import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Img from 'gatsby-image/withIEPolyfill'
import ToggleComponentContext from '@providers/toggleComponentProvider'
import Carousel from '@objects/carousel'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    position: 'relative',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),

    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(16),
      marginRight: theme.spacing(16),
    },
  },
  carouselRoot: {
    '&.swiper-container': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    '& .swiper-slide': {
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      pointerEvents: 'auto',
      '& .gatsby-image-wrapper': {
        height: '100%',
        minWidth: '100%',
        transition: 'transform 700ms ease-in-out',
      },
      '&:hover, &:focus': {
        '& .gatsby-image-wrapper': {
          transform: 'scale(1.05)',
        },
      },
    },
    paginationFraction: {
      lineHeight: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing(7),
      [theme.breakpoints.up('lg')]: {
        justifyContent: 'flex-end',
        margin: theme.spacing(7, 35, 2, 38),
      },
    },
  },
  navIcon: {
    position: 'relative',
    fontSize: theme.spacing(6),
    '&.lgNavIcon': {
      fontSize: theme.spacing(11),
    },
  },
  galleryItemImage: {
    overflow: 'hidden',
    border: `${theme.palette.grey.light} solid 1px`,
    height: '260px',
    [theme.breakpoints.up('sm')]: {
      height: '160px',
    },
    [theme.breakpoints.up('md')]: {
      height: '145px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '337px',
    },
  },
  galleryItemIntro: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '23px',
    margin: 0,
    marginTop: theme.spacing(2),
  },
  navBelowWrapper: {
    display: 'flex',
    flexDirection: 'column-reverse',
  },

  maskedOverflow: {
    [theme.breakpoints.up('md')]: {
      maskImage:
        'linear-gradient(to right, transparent, black 10%, black 90% ,transparent)',
    },
  },
  mediaSlide: {
    border: 0,
    padding: 0,
    background: 'transparent',
    cursor: 'pointer',
    fontFamily: theme.typography.fontFamily,
    textAlign: 'left',
    width: '100%',
  },
}))

function MediaTeaserCarousel({ className, items, id }) {
  const { toggleOverlay } = useContext(ToggleComponentContext)
  const classes = useStyles({ calculateArrowMarginTop })

  function calculateArrowMarginTop(imageHeight, arrowSize) {
    return (imageHeight - arrowSize) / 2
  }

  function onSwiperSlideClick(index) {
    openDialog(items[index])
  }

  function openDialog(mediaItem) {
    if (mediaItem.printMaterial) {
      toggleOverlay(true, 'displayPrintMaterial', 'default', {
        trackingID: 'dialog',
        printMaterial: mediaItem.printMaterial,
        hasButton: true,
        width: 'xl',
      })
    } else {
      toggleOverlay(true, 'displayMedia', 'default', {
        trackingID: 'dialog',
        mediaItem,
        hasButton: true,
      })
    }
  }

  return (
    <div
      data-testid="mediaTeaserCarousel"
      className={clsx(
        className,
        classes.mainContainer,
        classes.maskedOverflow,
        classes.navBelowWrapper
      )}
    >
      <Carousel
        carouselKey={`carousel-${id}`}
        settings={{
          breakpoints: {
            640: {
              slidesPerView: 1.5,
              spaceBetween: 24,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 28,
            },
            1024: {
              slidesPerView: 2.5,
              spaceBetween: 32,
            },
          },
          watchOverflow: true,
        }}
        onSlideClick={onSwiperSlideClick}
        className={clsx(classes.carouselRoot, 'media-teaser-carousel')}
        pagination="fraction"
        carouselId="teaserCarousel"
        swiperSlideClass="swiper-slide-teaser"
        navBelow={true}
        navigationOpts={{
          useExternal: true,
        }}
      >
        {items.map((item, index) => {
          let thumbnailFluid, alt, title
          if (item.printMaterial) {
            thumbnailFluid = item.printMaterial.images[0].fluid
            alt = item.printMaterial.images[0].description
            title = item.printMaterial.title
          } else {
            alt = item.alt
            title = item.title
            if (!!item.thumbnail) {
              thumbnailFluid = {
                ...item.thumbnail,
                media: `(min-width: 0px)`,
              }
            } else {
              thumbnailFluid = {
                aspectRatio: 1.7778,
                src: `https://img.youtube.com/vi/${item.youTubeKey}/maxresdefault.jpg`,
                srcSet: `https://img.youtube.com/vi/${item.youTubeKey}/maxresdefault.jpg`,
                sizes: '',
                media: `(min-width: 0px)`,
              }
            }
          }

          return (
            <button className={classes.mediaSlide} key={`media-slide-${index}`}>
              <div className={classes.galleryItemImage}>
                <Img
                  fluid={thumbnailFluid}
                  alt={alt}
                  backgroundColor="grey"
                  objectFit={item.printMaterial ? 'contain' : 'cover'}
                />
              </div>
              <p className={classes.galleryItemIntro}>{title}</p>
            </button>
          )
        })}
      </Carousel>
    </div>
  )
}

MediaTeaserCarousel.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  items: PropTypes.array,
  navigation: PropTypes.object,
  breakpoints: PropTypes.object,
}

export default MediaTeaserCarousel
