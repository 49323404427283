import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Headline from '@objects/headline'
import MediaTeaserCarousel from '@objects/mediaTeaserCarousel'
import { focalPointsTable } from '@tools/focalPoints'
import Container from '@objects/container'

import Button from '@objects/button'
import Icon from '@objects/icon'

import useDateFns from '@hooks/useDateFns'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: {},
  headline: {
    margin: 0,
  },
  mediaGrid: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(30),
  },
  mediaCarousel: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(30),
    '& ~ &': {
      marginTop: theme.spacing(15),
    },
  },
  carouselTitle: {
    fontSize: '24px',
    lineHeight: '28px',
    marginTop: 0,
    marginBottom: theme.spacing(8),
    color: theme.palette.text.hint,
  },
  navItem: {
    lineHeight: 'normal',
    display: 'inline-flex',
    marginRight: theme.spacing(7),

    marginBottom: theme.spacing(4),

    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(8),
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(4),
    },
  },
  navIcon: {
    marginLeft: theme.spacing(2),
  },
  mediaTeaserWrapper: {
    backgroundColor: '#f3f3f3',
  },
  mediaTeaserHeader: {
    textAlign: 'center',
    maxWidth: '1072px',
    paddingLeft: '16px',
    paddingRight: '16px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: theme.spacing(16),

    [theme.breakpoints.up('md')]: {
      paddingLeft: '16px',
      paddingRight: '16px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& a': {
      marginTop: theme.spacing(5),
      marginRight: theme.spacing(0),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(0),
        marginRight: theme.spacing(7),
      },
    },
  },
}))

function MediaTeaser({
  className,
  title,
  button,
  mediaTeaserList,
  isMediaPage,
  button2,
}) {
  const classes = useStyles()

  const NewMediaTeaserListObject = {
    id: 'homeTeaserListCarousel',
    title: 'item.title',
    files: mediaTeaserList,
    identifier: 'allMedia',
  }

  function createFluidImageSrc(image) {
    if (!image?.details?.image) return null
    return {
      aspectRatio: image.details.image.width / image.details.image.height,
      src: image.url + '?w=800&q=90',
      srcSet: `
        ${image.url}?w=${Math.round(
        image.details.image.width / 4
      )}&&q=90 ${Math.round(image.details.image.width / 4)}w,
        ${image.url}?w=${Math.round(
        image.details.image.width / 2
      )}&&q=90 ${Math.round(image.details.image.width / 2)}w,
        ${image.url}?w=${image.details.image.width}&&q=90 ${
        image.details.image.width
      }w,
        ${image.url}?w=${Math.round(
        image.details.image.width * 1.5
      )}&&q=90 ${Math.round(image.details.image.width * 1.5)}w,
        ${image.url}?w=1000&&q=90 1000w
      `,
      sizes: '(max-width: 800px) 100vw, 800px',
    }
  }

  function createContentfulImageCarouselItem(item) {
    return {
      thumbnail:
        item.image?.fluid ||
        item.thumbnail?.fluid ||
        item.thumbnailSmall?.fluid,
      file: item.image?.file,
      alt: item.intro?.intro,
      description: item.image?.description,
    }
  }
  function createContentfulVideoCarouselItem(item) {
    return {
      thumbnail: createFluidImageSrc(item.thumbnail?.file),
      file: item.media?.file,
      alt: item.media?.description,
      description: item.boxCopy?.boxCopy,
    }
  }
  function createContentfulYoutubeCarouselItem(item) {
    let thumbnail = null
    if (item.thumbnail) {
      thumbnail = createFluidImageSrc(item.thumbnail?.file)
    } else {
      thumbnail = {
        aspectRatio: 1.7778,
        src: `https://img.youtube.com/vi/${item.youTubeKey}/maxresdefault.jpg`,
        srcSet: `https://img.youtube.com/vi/${item.youTubeKey}/maxresdefault.jpg`,
        sizes: '',
        media: `(min-width: 0px)`,
      }
    }

    return {
      thumbnail: thumbnail,
      alt: item.thumbnail?.description,
      description: item.intro?.intro,
      youTubeKey: item.youTubeKey,
      id: item.id,
    }
  }

  function isPrintMaterialValid(item) {
    return item.images?.length > 0 && item.images[0].fluid
  }

  function createCarouselItems(mediaListItem) {
    const { formatDate } = useDateFns()
    //Filter
    const filterItems = mediaListItem.files?.filter((item) => {
      if (item.__typename === 'ContentfulImage' && !!item.image?.file)
        return true
      else if (item.__typename === 'ContentfulVideo' && !!item.thumbnail?.file)
        return true
      else if (item.__typename === 'ContentfulYoutube' && !!item.youTubeKey)
        return true
      else if (
        item.__typename === 'ContentfulPrintMaterial' &&
        isPrintMaterialValid(item)
      )
        return true
      return false
    })
    //Mapping
    const items = filterItems?.map((item) => {
      let mappedItem
      if (item.__typename === 'ContentfulImage')
        mappedItem = createContentfulImageCarouselItem(item)
      else if (item.__typename === 'ContentfulVideo')
        mappedItem = createContentfulVideoCarouselItem(item)
      else if (item.__typename === 'ContentfulPrintMaterial') {
        mappedItem = {
          printMaterial: item,
        }
      } else mappedItem = createContentfulYoutubeCarouselItem(item)
      mappedItem.type = item.__typename
      mappedItem.id = item.id
      mappedItem.title = item.title
      mappedItem.createdAt = formatDate(item.createdAt, 'dd.MM.yyyy')
      mappedItem.copyright = item.copyright
      return mappedItem
    })

    return items
  }

  function renderCarouselList(item) {
    const carouselItems = createCarouselItems(item)

    return (
      carouselItems && (
        <MediaTeaserCarousel items={carouselItems} key={item.id} id={item.id} />
      )
    )
  }

  return (
    <Container
      className={className}
      type={'default'}
      ariaLabel="arialabel.mediaTeaser"
      background="#f3f3f3"
      width="full"
    >
      <div className={classes.mediaTeaserWrapper}>
        <div className={classes.mediaTeaserHeader}>
          <Headline className={classes.headline} level={2}>
            {title}
          </Headline>

          <div>
            <Button
              className={classes.navItem}
              type="secondary"
              color="red"
              to={button}
              useScrollOffset={false}
              dataTracking="true"
              dataTrackingCategory="Button"
              dataTrackingAction="Klick"
            >
              Alle Medien
              <Icon
                className={classes.navIcon}
                name="TextArrow"
                size="inherit"
              />
            </Button>

            {!!isMediaPage && (
              <Button
                className={classes.navItem}
                type="secondary"
                color="red"
                to={button2}
                useScrollOffset={false}
                dataTracking="true"
                dataTrackingCategory="Button"
                dataTrackingAction="Klick"
              >
                Alle Printmaterialien
                <Icon
                  className={classes.navIcon}
                  name="TextArrow"
                  size="inherit"
                />
              </Button>
            )}
          </div>
        </div>

        <div
          key={_.uniqueId('media-teaser-')}
          data-testid="mediaItem"
          className={classes.mediaCarousel}
        >
          {renderCarouselList(NewMediaTeaserListObject)}
        </div>
      </div>
    </Container>
  )
}

MediaTeaser.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  mediaList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string.isRequired,
      thumbnail: PropTypes.shape({
        fluid: PropTypes.object,
      }),
      focalPoint: PropTypes.oneOf(Object.keys(focalPointsTable)),
      amount: PropTypes.number,
      files: PropTypes.array,
    })
  ),
}

export default MediaTeaser
